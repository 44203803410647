<!--上边栏，面包屑导航-->
<template>
	<v-app-bar app elevate-on-scroll>
		<v-container class="py-0 fill-height">
			<v-img class="mr-6" max-width="120" contain :src="img"></v-img>
			
			<div v-for="(menu, index) in NavbarMenu" :key="index">
				<router-link :to="{ path: menu.path }">
					<v-btn color="primary" text small>
						{{ menu["title_en"] }}
					</v-btn>
				</router-link>
			</div>
      <div>
        <router-link :to="{ path: '/calendar'}">
          <v-btn color="primary" text small>
            CALENDAR
          </v-btn>
        </router-link>
      </div>

			<v-spacer></v-spacer>

			<v-responsive
				v-if="isShow"
				max-width="300"
				max-height="40"
				class="mr-6"
			>
				<v-select
					dense
					return-object
					rounded
					solo
					flat
					clearable
					clear-icon="mdi-close-circle-outline"
					:items="team_list"
					v-model="team"
					item-text="name_en"
					item-value="id"
					label="Team"
					@change="setTeam(team)"
				>
				</v-select>
			</v-responsive>

			<div v-else class="text-center mr-5">{{ navbar_team_name }}</div>

			<v-icon class="mr-2">mdi-theme-light-dark</v-icon>
			<v-switch
				hide-details
				label=""
				v-model="$vuetify.theme.dark"
				@change="changeTheme"
			></v-switch>

			<v-menu offset-y>
				<template v-slot:activator="{ on, attrs }">
					<v-btn small rounded text v-bind="attrs" v-on="on">
						{{ username }}
					</v-btn>
				</template>
				<v-list dense>
					<v-list-item :to="{ path: '/settings' }" dense>
						<v-list-item-icon>
							<v-icon>mdi-account-cog</v-icon>
						</v-list-item-icon>
						<v-list-item-title> Settings</v-list-item-title>
					</v-list-item>
					<v-list-item @click="Logout" dense>
						<v-list-item-icon>
							<v-icon>mdi-logout</v-icon>
						</v-list-item-icon>
						<v-list-item-title> Sign Out</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-container>
	</v-app-bar>
</template>

<script>
export default {
	name: "Navbar",
	props: ["NavbarMenu"],
	data() {
		return {
			img: require("../../assets/images/sdc_logo_landscape.png"),
			team: JSON.parse(window.sessionStorage.getItem("team")),
			update: true,
			team_list: [],
			isShow:
				this.$store.getters.groups === 1 ||
				this.$store.getters.groups === 2,
			navbar_team_name: null,
			username:
				this.$store.getters.username !== null
					? this.$store.getters.username
					: null,
		};
	},
	watch: {
		"$store.getters.team_list": {
			handler(newValue) {
				this.team_list = newValue;
			},
		},
		"$store.getters.team": {
			handler(newValue) {
				this.navbar_team_name =
					newValue !== null ? newValue.name_en : null;
			},
		},
	},
	mounted() {
		if (JSON.parse(window.sessionStorage.getItem("team")) !== null) {
			this.$store.commit(
				"SET_TEAM",
				JSON.parse(window.sessionStorage.getItem("team"))
			);
		}
		this.team_list =
			this.$store.getters.team_list !== null
				? this.$store.getters.team_list
				: [];
	},
	methods: {
		setTeam(team) {
			window.sessionStorage.setItem("team", JSON.stringify(team));
			this.$store.commit("SET_TEAM", team);
		},
		clearTeam() {
			window.sessionStorage.removeItem("team");
			this.$store.commit("SET_TEAM", null);
			this.team = null;
		},
		Logout() {
			this.$store.dispatch("LogOut").then();
		},

		changeTheme() {
			if (this.$vuetify.theme.dark === true) {
				window.sessionStorage.setItem("theme", "dark");
			} else {
				window.sessionStorage.setItem("theme", "light");
			}
		},
	},
};
</script>

<style scoped>
a {
	text-decoration: none;
}

.router-link-active {
	text-decoration: none;
}
</style>