export function getToken() {
  return window.localStorage.token
}

export function setToken(Token, token) {
   return window.localStorage.setItem(Token, token);
}

export function removeToken(Token) {
  return window.localStorage.removeItem(Token);
}