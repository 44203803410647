import request from "../../utils/request";

// 查询组织列表
export function listInstitute(params) {
  return request({
    url: '/api/solar/institutes/',
    method: 'get',
    isToken: false,
    params: params
  })
}


// 查询单个组织详情
export function getInstitute(instituteId) {
  return request({
    url: '/api/solar/institutes/' + instituteId + '/',
    method: 'get',
  })
}


// 新增组织
export function addInstitute(data) {
  return request({
    url: '/api/solar/institutes/',
    method: 'post',
    data: data
  })
}


// 修改组织(全部修改)
export function updateInstitutePut(instituteId, data) {
  return request({
    url: '/api/solar/institutes/' + instituteId + '/',
    method: 'put',
    data: data
  })
}


// 修改组织(单个字段修改)
export function updateInstitutePatch(instituteId, data) {
  return request({
    url: '/api/solar/institutes/' + instituteId + '/',
    method: 'patch',
    data: data
  })
}


// 删除组织
export function delInstitute(instituteId) {
  return request({
    url: '/api/solar/institutes/' + instituteId + '/',
    method: 'delete'
  })
}
