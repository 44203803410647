import Vuex from "vuex";
import Vue from "vue";
import user from "./modules/user";
import getters from "./getters";
import team from "./modules/team";
import menu from "./modules/menu";

Vue.use(Vuex);


const store = new Vuex.Store({
    modules: {
        user,
        team,
        menu,
    },
    getters,
});

export default store;
