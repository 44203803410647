import {getToken, removeToken, setToken} from "@/utils/auth";
import {getInfo, login} from "@/api/login";
import router from "../../router";

const user = {
    state: {
        token: getToken(),
        username: null,
        avatar: null,
        user_team: null,
        user_id: null,
        groups:[],
        password_expired: null,
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_NAME: (state, username) => {
            state.username = username
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar
        },
        SET_USERID: (state, user_id) => {
            state.user_id = user_id
        },
        SET_USER_TEAM: (state, user_team) => {
            state.user_team = user_team
        },
        SET_USER_GROUPS: (state, groups) => {
            state.groups = groups
        },
        SET_PasswordExpired: (state, password_expired) => {
            state.password_expired = password_expired
        }
    },

    actions: {
        // 登录
        Login({ commit }, userInfo) {
            const username = userInfo.username
            const password = userInfo.password
            return new Promise((resolve, reject) => {
                login(username, password).then( res => {
                    setToken("token", res.access)
                    commit('SET_TOKEN', res.access)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 获取用户信息
        GetInfo({ commit} ) {
            return new Promise((resolve, reject) => {
                getInfo().then(res => {
                    commit('SET_PasswordExpired', res.password_expired)
                    commit('SET_NAME', res.username)
                    commit('SET_AVATAR', res.avatar === null ? require('../../assets/images/sdc_logo_portrait.png') : res.avatar)
                    commit('SET_USERID', res.id)
                    commit('SET_USER_TEAM', res.team)
                    commit('SET_USER_GROUPS', res.groups[0]['id'])
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },


        // 退出
        LogOut( { commit }) {
            removeToken("token")
            commit('SET_NAME', null)
            commit('SET_AVATAR', null)
            commit('SET_USERID', null)
            commit('SET_USER_TEAM', null)
            commit('SET_USER_GROUPS', null)
            commit('SET_TEAM_LIST', null)
            commit('SET_TEAM', null)
            commit('SET_MENU_LIST', null)
            commit('SET_MENU', null)
            window.sessionStorage.removeItem("country_list")
            window.sessionStorage.removeItem("institute_list")
            window.sessionStorage.removeItem("menu_list")
            window.sessionStorage.removeItem("team")
            router.push({name: "Login"}).catch()
        }
    }
}

export default user
