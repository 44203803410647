import request from "../../utils/request";

// 查询赛队列表
export function listTeam() {
  return request({
    url: '/api/solar/teams/',
    method: 'get',
    params: {
      pagesize: 20,
    }
  })
}


// 查询单个赛队详情
export function getTeam(teamId) {
  return request({
    url: '/api/solar/teams/' + teamId + '/',
    method: 'get',
  })
}


// 新增赛队
export function addTeam(data) {
  return request({
    url: '/api/solar/teams/',
    method: 'post',
    data: data
  })
}


// 修改赛队(全部修改)
export function updateTeamPut(teamId, data) {
  return request({
    url: '/api/solar/teams/' + teamId + '/',
    method: 'put',
    data: data
  })
}


// 修改赛队(单个字段修改)
export function updateTeamPatch(teamId, data) {
  return request({
    url: '/api/solar/teams/' + teamId + '/',
    method: 'patch',
    data: data
  })
}


// 删除赛队
export function delTeam(teamId) {
  return request({
    url: '/api/solar/teams/' + teamId + '/',
    method: 'delete'
  })
}


// 管理员页面
export function adminDashboard() {
  return request({
    url: '/api/solar/teams/admin_dashboard/',
    method: 'get'
  })
}


// 赛队页面
export function teamDashboard(teamId) {
  return request({
    url: '/api/solar/teams/' + teamId + '/team_dashboard/',
    method: 'get'
  })
}


export function Median(teamId) {
  return request({
    url: `/api/solar/teams/${teamId}/median/`,
    method: 'get'
  })
}

export function dayScore(params) {
  return request({
    url: `/api/solar/dayscores/`,
    method: 'get',
    params: params,
  })
}

export function Progress(params) {
  return request({
    url: `/api/solar/progress/`,
    method: 'get',
    params: params,
  })
}
