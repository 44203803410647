import {listMenu} from "@/api/system/menu";

const menu = {
    state: {
        menu_list: null,
        menu: null,
    },

    mutations: {
        SET_MENU_LIST: (state, menu_list) => {
            state.menu_list = menu_list
        },
        SET_MENU: (state, menu) => {
            state.menu = menu
        },
    },

    actions: {
        GetMenuList({ commit} ) {
            return new Promise((resolve, reject) => {
                listMenu().then(res => {
                    commit('SET_MENU_LIST', res.results)
                    window.sessionStorage.setItem('menu_list', JSON.stringify(res.results) )
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
    }
}

export default menu