import {getTeam, listTeam} from "@/api/solar/team";

const team = {
    state: {
        team_list: null,
        team: null,
        InfoAnnouncement: true,
    },

    mutations: {
        SET_TEAM_LIST: (state, team_list) => {
            state.team_list = team_list
        },
        SET_TEAM: (state, team) => {
            state.team = team
        },
        SET_InfoAnnouncement: (state, status) => {
            state.InfoAnnouncement = status
        },
    },

    actions: {
        // 获取用户信息
        GetTeamList({ commit} ) {
            return new Promise((resolve, reject) => {
                listTeam().then(res => {
                    commit('SET_TEAM_LIST', res.results)
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        GetTeam({ commit}, teamId ) {
            return new Promise((resolve, reject) => {
                getTeam(teamId).then(res => {
                    commit('SET_TEAM', res)
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
    }
}

export default team
