import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {i18n, vuetify} from "@/plugins/vuetify";
import { Message, DatePicker } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import DatetimePicker from 'vuetify-datetime-picker'

// 全局前置路由守卫
import './permission'
import "./styles/index.css"

Vue.config.productionTip = false

// 按需导入element-ui
Vue.prototype.$message = Message;

Vue.use(DatePicker);
Vue.use(DatetimePicker)

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
