<!--页面布局父组件-->
<template>
  <v-app>
    <Navbar :NavbarMenu="NavbarMenu"></Navbar>
    <v-main class="bg">
      <v-container>
        <v-row>
          <Sidebar :ContestSidebarMenu="ContestSidebarMenu" :MoreSidebarMenu="MoreSidebarMenu"></Sidebar>
          <Main></Main>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Main from "./components/Main";
export default {
  name: 'Layout',

  data(){
    return {
      NavbarMenu: [],
      ContestSidebarMenu: [],
      MoreSidebarMenu: [],
      dark: true
    }
  },
  watch:{
    "$store.getters.menu_list":{
      handler(){
        this.GetListMenu()
      }
    },
  },

  components: {
    Navbar,
    Sidebar,
    Main,
  },
  // 页面渲染时执行
  async created() {
  },
  async mounted(){
    this.GetListMenu()
  },
  methods:{
    GetListMenu(){
      if (this.$store.getters.menu_list){
        this.NavbarMenu = []
        this.ContestSidebarMenu = []
        this.MoreSidebarMenu = []
        this.$store.getters.menu_list.forEach((item) => {
          if (item['location'] === 2) {
            this.NavbarMenu.push(item)
          }
          if (item['location'] === 1) {
            this.ContestSidebarMenu.push(item)
          }
          if (item['location'] === 3) {
            this.MoreSidebarMenu.push(item)
          }
        })
      }
    },
  }
}
</script>

<style scoped>

</style>