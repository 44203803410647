import request from "../../utils/request";

// 查询通知信息列表
export function listAnnouncement(params) {
  return request({
    url: '/api/solar/announcements/',
    method: 'get',
    params: params,
  })
}


// 查询单个通知信息详情
export function retrieveAnnouncement(announcementId) {
  return request({
    url: `/api/solar/announcements/${announcementId}/`,
    method: 'get',
  })
}


// 新增通知信息
export function createAnnouncement(data) {
  return request({
    url: `/api/solar/announcements/`,
    method: 'post',
    data: data
  })
}


// 修改通知信息(全部修改)
export function updateAnnouncementPut(announcementId, data) {
  return request({
    url: `/api/solar/announcements/${announcementId}/`,
    method: 'put',
    data: data
  })
}


// 修改通知信息(单个字段修改)
export function updateAnnouncementPatch(announcementId, data) {
  return request({
    url: `/api/solar/announcements/${announcementId}/`,
    method: 'patch',
    data: data
  })
}


// 删除通知信息
export function destroyAnnouncement(announcementId) {
  return request({
    url: `/api/solar/announcements/${announcementId}/`,
    method: 'delete'
  })
}


// 删除通知信息
export async function infoAnnouncement() {
  return request({
    url: `/api/solar/announcements/info/`,
    method: 'get'
  })
}