<!--@Time : 2021/11/5 12:05-->
<!--@Author : WangHaoRan-->
<!--@Annotation :主视图-->
<template>
	<v-col cols="10" lg="10" xl="10">
		<v-sheet min-height="70vh" rounded="lg" class="pa-12">
			<router-view></router-view>
		</v-sheet>
	</v-col>
</template>

<script>
import { listCountry } from "@/api/solar/country";
import {listInstitute} from "@/api/solar/institute";

export default {
	name: "Main",
	data() {
		return {
			isReloadAlive: true,
		};
	},
	created() {
		listCountry({ pagesize: 300 }).then((res) => {
			window.sessionStorage.setItem("country_list", JSON.stringify(res["results"]));
		});
    listInstitute({ pagesize: 300 }).then((res) => {
			window.sessionStorage.setItem("institute_list", JSON.stringify(res.results));
    });
	},
	methods: {},
};
</script>

<style scoped>
</style>