<!--侧边栏-->
<template>
	<v-col cols="2" lg="2" xl="2">
		<v-sheet
			class="mx-auto"
			rounded="lg"
			style="position: sticky; top: 76px"
		>
			<v-list color="transparent" dense>
				<v-list-item>
					<v-list-item-avatar>
						<v-img :src="team_logo"></v-img>
					</v-list-item-avatar>
				</v-list-item>

				<v-list-item>
					<v-list-item-content>
						<v-list-item-title class="title"
							>Solar Decathlon China
						</v-list-item-title>
						<v-list-item-subtitle class="text-overline"
							>Zhangjiakou 2022
						</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>

				<v-divider class="my-1"></v-divider>

				<v-list-item-group v-model="selectedItem" color="primary">
					<v-subheader class="text-uppercase">Contests</v-subheader>
					<!--路由传参，携带菜单信息-->
					<v-list-item
						v-for="(menu, index) in ContestSidebarMenu"
						:key="'contest' + index"
						:to="{ path: menu.path }"
					>
						<v-list-item-icon>
							<v-icon v-text="menu.icon"></v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title
								v-text="menu['title_en']"
							></v-list-item-title>
						</v-list-item-content>
					</v-list-item>

					<v-divider class="my-1"></v-divider>

					<v-subheader class="text-uppercase">More</v-subheader>
					<v-list-item
						v-for="(menu, index) in MoreSidebarMenu"
						:key="'more' + index"
						:to="{ path: menu.path }"
					>
						<v-list-item-icon>
							<v-icon v-text="menu.icon"></v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title
								v-text="menu['title_en']"
							></v-list-item-title>
						</v-list-item-content>

							<v-avatar color="red" size="10" v-if="menu['title_en'] === 'Announcements' && info">
                <span class="white--text headline"></span>
              </v-avatar>

					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-sheet>
	</v-col>
</template>

<script>
import {infoAnnouncement} from "@/api/solar/announcement";

export default {
	name: "Sidebar",
	props: ["ContestSidebarMenu", "MoreSidebarMenu"],
	data() {
		return {
			selectedItem: 1,
			team_logo: require("../../assets/images/sdc_logo_portrait.png"),
      info: false
		};
	},
	watch: {
		"$store.getters.team": {
			handler(newValue) {
				if (newValue) {
					this.team_logo = `${process.env.VUE_APP_BACKEND_URL}${newValue.logo}`;
				} else {
					this.team_logo = require("../../assets/images/sdc_logo_portrait.png");
				}
			},
		},
    "$store.getters.InfoAnnouncement":{
      handler(val){
        this.info = val
      }
    }
	},
  created() {
    infoAnnouncement().then( (res)=>{
      this.$store.commit("SET_InfoAnnouncement", res['info'])
      this.info = this.$store.getters.InfoAnnouncement
    })
  },

  mounted() {},
};
</script>

<style scoped>
</style>