<template>
  <v-app id="app" :style="$vuetify.theme.dark ? { background: 'rgb(18,18,18)'} : { background: 'rgb(238,238,238)'}">
    <router-view/>
  </v-app>
</template>

<script>
export default {
  name: "App",
  async mounted() {
    window.sessionStorage.getItem("theme") === "dark" ? (this.$vuetify.theme.dark = true) : (this.$vuetify.theme.dark = false);
  },
}
</script>

<style scoped>

</style>
