import request from "../utils/request";


// 登录方法
export function login(username, password) {
    const data = {
        username,
        password,
    }
    return request({
        url: '/api/login/',
        method: 'post',
        data: data
    })
}


// 获取用户详细信息
export function getInfo() {
    return request({
        url: '/api/user/users/userinfo/',
        method: 'get'
    })
}


// 退出登录
export function logout(){
    return request({
        url: '/logout',
        method: 'post'
    })
}
