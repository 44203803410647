import request from "../../utils/request";

// 查询菜单列表
export function listMenu(params) {
  return request({
    url: '/api/system/menus/',
    method: 'get',
    params: params
  })
}


// 查询单个菜单详情
export function getMenu(menuId) {
  return request({
    url: '/api/system/menus/' + menuId + '/',
    method: 'get',
  })
}


// 新增菜单
export function addMenu(data) {
  return request({
    url: '/api/system/menus/',
    method: 'post',
    data: data
  })
}


// 修改菜单(全部修改)
export function updateMenuPut(menuId, data) {
  return request({
    url: '/api/system/menus/' + menuId + '/',
    method: 'put',
    data: data
  })
}


// 修改菜单(单个字段修改)
export function updateMenuPatch(menuId, data) {
  return request({
    url: '/api/system/menus/' + menuId + '/',
    method: 'patch',
    data: data
  })
}


// 删除菜单
export function delMenu(menuId) {
  return request({
    url: '/api/system/menus/' + menuId + '/',
    method: 'delete'
  })
}