const getters = {
    token: state => state.user.token,
    avatar: state => state.user.avatar,
    username: state => state.user.username,
    user_id: state => state.user.user_id,
    user_team: state => state.user.user_team,
    groups: state => state.user.groups,
    team_list: state => state.team.team_list,
    team: state => state.team.team,
    InfoAnnouncement: state => state.team.InfoAnnouncement,
    password_expired: state => state.user.password_expired,
    menu: state => state.menu.menu,
    menu_list: state => state.menu.menu_list,
}

export default getters