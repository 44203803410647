import Vue from 'vue'
import VueRouter from 'vue-router'
import 'nprogress/nprogress.css'
import Layout from '../layout'


Vue.use(VueRouter)

const routes = [
    {
        path: '/home',
        name: 'Home',
        component: Layout,
        redirect: '/dashboard',
        children: [
            {
                path: '/dashboard',
                name: 'Dashboard',
                component: () => import('../views/breadcrumb/dashboard/Dashboard.vue'),
            },
            {
                path: '/profile',
                name: 'Profile',
                component: () => import('../views/breadcrumb/profile/Profile.vue'),
            },
            {
                path: '/standing',
                name: 'Standing',
                component: () => import('../views/breadcrumb/Standing.vue'),
            },
            {
                path: '/weather',
                name: 'Weather',
                component: () => import('../views/breadcrumb/Weather.vue'),
            },
            {
                path: '/calendar',
                name: 'Calendar',
                component: () => import('../views/breadcrumb/Calendar.vue'),
            },
            {
                path: '/architecture',
                name: 'Architecture',
                component: () => import('../views/contest/Architecture.vue'),
            },
            {
                path: '/engineering',
                name: 'Engineering',
                component: () => import('../views/contest/Engineering.vue'),
            },
            {
                path: '/energy',
                name: 'Energy',
                component: () => import('../views/contest/Energy.vue'),
            },
            {
                path: '/communications',
                name: 'Communications',
                component: () => import('../views/contest/Communications.vue'),
            },
            {
                path: '/market',
                name: 'Market',
                component: () => import('../views/contest/Market.vue'),
            },
            {
                path: '/renewable',
                name: 'Renewable',
                component: () => import('../views/contest/Renewable.vue'),
            },
            {
                path: '/indoor',
                name: 'Indoor',
                component: () => import('../views/contest/Indoor.vue'),
            },
            {
                path: '/homelife',
                name: 'Homelife',
                component: () => import('../views/contest/Homelife.vue'),
            },
            {
                path: '/interactive',
                name: 'Interactive',
                component: () => import('../views/contest/Interactive.vue'),
            },
            {
                path: '/self-sufficiency',
                name: 'SelfSufficiency',
                component: () => import('../views/contest/Selfsufficiency.vue'),
            },
            {
                path: '/announcements',
                name: 'Announcements',
                component: () => import('../views/more/Announcements.vue'),
            },
            {
                path: '/documents',
                name: 'Documents',
                component: () => import('../views/more/Documents.vue'),
            },
            {
                path: '/users',
                name: 'Users',
                component: () => import('../views/more/Users.vue'),
            },
            {
                path: '/export',
                name: 'Export',
                component: () => import('../views/more/Export/Export.vue'),
            },
            {
                path: '/penalty',
                name: 'Penalty',
                component: () => import('../views/more/Penalty.vue'),
            },
            {
                path: '/theme',
                name: 'theme',
                component: () => import('../views/more/Theme.vue'),
            },
            {
                path: '/schools',
                name: 'Schools',
                component: () => import('../views/more/Schools.vue'),
            },
            {
                path: '/settings',
                name: 'Settings',
                component: () => import('../views/Settings'),
            },
        ]
    },
    {
        path: '/',
        name: 'Login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/index',
        name: 'Index',
        component: () => import('../views/index/Index'),
        redirect: '/chart',
        children: [
            {
                path: '/results',
                name: 'Results',
                component: () => import('../views/index/Results.vue'),
            },
            {
                path: '/chart',
                name: 'Chart',
                component: () => import('../views/index/Chart.vue'),
            },
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    routes
})

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
