import router from './router'
import store from './store'
import { Message } from 'element-ui'
import {getToken} from "./utils/auth";
import NProgress from 'nprogress'


// 全局前置路由守卫
router.beforeEach((to, from, next) => {
    NProgress.start()
    if (getToken()) {
        // 有token
        if (to.path === '/') {
            // 在登录页直接跳转到主页
            next({ path: '/dashboard' })
            NProgress.done()
        }

        else {
            // 不在登录页
            if (store.getters.user_id === null || store.getters.user_id === '') {
                // 判断当前用户是否已拉取完user_info信息
                store.dispatch('GetInfo').then((res) => {
                    store.dispatch('GetTeamList').then().catch()
                    store.dispatch('GetMenuList').then().catch()
                    if (res.team !== null) {
                        store.dispatch('GetTeam', res.team.id).then().catch()
                    }
                    // 如果第一次登录，跳转到settings修改密码
                    if (store.getters.password_expired === false) {
                        next({ path: '/Settings' })
                        NProgress.done()
                    }
                    //不是第一次登录
                    else {
                        if (window.sessionStorage.getItem('menu_list')){
                            JSON.parse(window.sessionStorage.getItem('menu_list')).some((item)=>{
                                if(to.path===item['path']){
                                    store.commit('SET_MENU', item);
                                    next({path: item['path']} );
                                    NProgress.done();
                                    return true;
                                }
                                else {
                                    if (to.path === '/results') {
                                        next()
                                        NProgress.done()
                                    }
                                    else {
                                        if (to.path === '/chart') {
                                            next()
                                            NProgress.done()
                                        }
                                        else {
                                            next({ path: '/dashboard' })  // 否则全部重定向到
                                            NProgress.done()
                                        }
                                    }
                                }
                            })
                        }
                        else {
                            next({})
                            NProgress.done()
                        }
                    }
                }).catch(err => {
                    store.dispatch('LogOut').then(() => {
                        Message.error(err)
                        next({ path: '/' })
                        NProgress.done()
                    })
                })
            }
            else {
                if (window.sessionStorage.getItem('menu_list')){
                    JSON.parse(window.sessionStorage.getItem('menu_list')).some((item)=>{
                        if(to.path===item['path']){
                            store.commit('SET_MENU', item);
                            return true;
                        }
                    })
                }
                next()
                NProgress.done()
            }
        }
    }
    else {
        if (to.path === '/') {
            next()
            NProgress.done()
        }
        else {
            if (to.path === '/results') {
                next()
                NProgress.done()
            }
            else {
                if (to.path === '/chart') {
                    next()
                    NProgress.done()
                }
                else {
                    next({ path: '/' })  // 否则全部重定向到登录页
                    NProgress.done()
                }
            }
        }
    }
})
