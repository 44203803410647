import request from "../../utils/request";

// 查询国家列表
export function listCountry(params) {
  return request({
    url: '/api/solar/countries/',
    method: 'get',
    params: params,
  })
}


// 查询单个国家详情
export function getCountry(countryId) {
  return request({
    url: '/api/solar/countries/' + countryId + '/',
    method: 'get',
  })
}


// 新增国家
export function addCountry(data) {
  return request({
    url: '/api/solar/countries/',
    method: 'post',
    data: data
  })
}


// 修改国家(全部修改)
export function updateCountryPut(countryId, data) {
  return request({
    url: '/api/solar/countries/' + countryId + '/',
    method: 'put',
    data: data
  })
}


// 修改国家(单个字段修改)
export function updateCountryPatch(countryId, data) {
  return request({
    url: '/api/solar/countries/' + countryId + '/',
    method: 'patch',
    data: data
  })
}


// 删除国家
export function delCountry(countryId) {
  return request({
    url: '/api/solar/countries/' + countryId + '/',
    method: 'delete'
  })
}


// 查询参赛的国家，队伍，组织
 export async function getMessage() {
  return request({
    url: '/api/solar/countries/message/',
    method: 'get',
    isToken: false,
  })
}


// 查询参赛的国家名称
export function getParticipate() {
  return request({
    url: '/api/solar/countries/participate/',
    method: 'get',
    isToken: false,
  })
}