import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import colors from 'vuetify/lib/util/colors'
import VueI18n from 'vue-i18n'

Vue.use(Vuetify)
Vue.use(VueI18n)

const opts = {
    icons: {
        iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
}

const messages = {
    cn: {
        $vuetify: {
            language: require('../language/cn.json'),
        },
    },
    en: {
        $vuetify: {
            language: require('../language/en.json'),
        },
    },
}

// Create VueI18n instance with options
const i18n = new VueI18n({

    // 当前选用的语言
    locale: 'en', // set locale

    // 所有的语言包
    messages, // set locale messages

    // 隐藏警告
    silentTranslationWarn: true
})

const vuetify = new Vuetify({
    opts,
    theme: {
        themes: {
            light: {
                primary: colors.lightBlue.base,
                secondary: colors.grey.lighten2,
                accent: colors.deepOrange.base,
                error: colors.red.accent2,
                info: colors.blue.base,
                success: colors.green.base,
                warning: colors.amber.darken2,

                // 当以上标准的颜色变量不能满足需求时，程序员也可以自定义颜色变量。请注意两种主题要保持变量名一致。
                bg: "#eeeeee"
            },
            dark: {
                primary: colors.lightBlue.base,
                secondary: colors.grey.darken4,
                accent: colors.deepOrange.darken3,
                error: colors.red.accent4,
                info: colors.blue.darken3,
                success: colors.green.darken3,
                warning: colors.amber.darken3,

                // 自定义颜色变量。请注意两种主题要保持变量名一致。
                bg: "#000000"
            }
        },
    },
    // 替换后vuetify的文本失效
    // lang: {
    //     t: (key, ...params) => i18n.t(key, params),
    // },

    breakpoint: {
        thresholds: {
            xs: 340,
            sm: 540,
            md: 800,
            lg: 1280,
        },
        scrollBarWidth: 24,
    },
})

export {i18n, vuetify}
